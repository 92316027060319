.actionBar {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.forgotPasswordBtns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-block-start: 1rem;
    gap: 0.5rem;
}

.underlinks {
    text-align: center;
    padding-block: 2em;
}

@media (max-width: 500px) {
    .forgotPasswordBtns {
        grid-template-columns: auto;
        margin-block-start: 3rem;
    }
}
