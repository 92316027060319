.option {
    cursor: pointer;
    border-bottom: 1px solid var(--border);
    padding: var(--padding-small);
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: var(--padding-small);
    align-items: center;
    &:last-of-type {
        border-bottom: none;
        border-bottom-left-radius: var(--rounded-corners-pill);
        border-bottom-right-radius: var(--rounded-corners-pill);
    }
    &:first-of-type {
        border-top-left-radius: var(--rounded-corners-pill);
        border-top-right-radius: var(--rounded-corners-pill);
    }
    &.focussed {
        box-shadow: 0 0 0 1px var(--blue);
    }
    &:hover {
        background: var(--bg-2);
    }
}

.optionType {
    color: var(--text-muted);
}

.optionLogo {
    height: 1.5em;
    width: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}
