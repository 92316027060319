.actionBar {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;

    .nextBtn {
        width: 100%;
        cursor: pointer;
    }
}

.otherProviderBtns {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: var(--margin-big);

    .or {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: var(--text-muted);

        &::before,
        &::after {
            content: '';
            flex: 1;
            height: 1px;
            background-color: var(--border);
            width: 100%;
            margin-block: var(--margin-big);
        }

        &::before {
            margin-right: var(--margin-big);
        }

        &::after {
            margin-left: var(--margin-big);
        }
    }

    .socialBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        border-radius: var(--border-radius);
        text-align: center;
        margin-top: var(--margin);
        transition: all 0.1s linear;

        &:hover {
            cursor: pointer;
            color: var(--brand-color);
            background-color: var(--brand-hover-color);
            border-color: var(--brand-color);
        }

        &.iconWrapper {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 0%;
        }

        & .btnText {
            flex: 1 1 0%;
            text-align: center;
            font-weight: var(--font-weight-bolder);
        }

        &:disabled .iconWrapper {
            display: none;
        }
    }

    .facebookBtn {
        @extend .socialBtn;
    }

    .googleBtn {
        @extend .socialBtn;
    }
}

.message {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
