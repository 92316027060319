.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button svg {
    height: 50%;
    margin-inline-end: 0.5em;
}

.spinner {
    margin-inline-end: 0.5em;
}

.spinnerDark {
    stroke: var(--brand-color);
}
