.outer {
    box-sizing: border-box;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: outer;
}

@keyframes outer {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.inner {
    box-sizing: border-box;
    stroke-width: 12;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke-dashoffset: 600;
    stroke-dasharray: 300;
    stroke: var(--white);
    fill: transparent;
    animation-duration: 1.6s;
    animation-timing-function: cubic-bezier(0.4, 0.15, 0.6, 0.85);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: inner;
}

@keyframes inner {
    0% {
        stroke-dashoffset: 600;
    }

    100% {
        stroke-dashoffset: 0;
    }
}
