.dropdownContent {
    min-width: 220px;
    max-width: 320px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 1px solid var(--border);
    box-shadow: var(--card-box-shadow);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 11;
}

.dropdownContent[data-side='top'] {
    animation-name: slideDownAndFade;
}

.dropdownContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}

.dropdownContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.dropdownContent[data-side='left'] {
    animation-name: slideRightAndFade;
}

.dropdownLabel {
    padding: var(--padding-small);
    color: var(--text-muted);
    padding-block-end: var(--padding-tiny);
    font-size: var(--font-size-smaller);
}

.dropdownItem {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: var(--padding-tiny);
    align-items: center;
    padding: var(--padding-small);
    color: var(--text);
    font-size: var(--font-size-smaller);

    &:hover {
        background: var(--bg-2);
    }
}

.separator {
    height: 1px;
    background-color: var(--border);
}

.containerArrow {
    fill: white;
    stroke: var(--border);
}

.logout {
    color: var(--red);
    fill: var(--red);
}

div.educatorLogo {
    width: 20px;
    height: 20px;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
