.requestNewCodeButton {
    display: inline;
    font-size: var(--font-size);
    color: var(--blue);
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline var(--light-blue);
    padding: 0px;

    &:hover {
        text-decoration: underline var(--blue);
    }

    &[disabled] {
        color: var(--grey);
        cursor: not-allowed;

        &:hover {
            text-decoration: underline var(--light-blue);
        }
    }
}
