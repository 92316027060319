.container {
    display: flex;
    flex-direction: column;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-block-start: var(--padding);
}
