.card {
    --_backgroundColor: var(--cardBackground, white);
    background-color: var(--_backgroundColor);
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
}

.card.paddedCard {
    padding: var(--padding);
}
