.comboboxContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.combobox {
    width: 100%;
    padding: 14px;
    padding-inline-start: calc(var(--padding-small) * 4);
    box-sizing: border-box;
    border: 1px solid var(--border);
    border-inline-end: 0.5px solid var(--border);
    border-top-left-radius: var(--rounded-corners-pill);
    border-bottom-left-radius: var(--rounded-corners-pill);
    &.fullWidth {
        border-top-right-radius: var(--rounded-corners-pill);
        border-bottom-right-radius: var(--rounded-corners-pill);
    }
}

.inputIcon {
    position: absolute;
    left: 1.125em; //18px
    width: var(--font-size);
    height: var(--font-size);
}

.clearIcon {
    all: unset;
    position: absolute;
    right: 3.5em;
    fill: var(--icon-fill-light);
    width: var(--font-size);
    height: var(--font-size);
    cursor: pointer;
    svg {
        width: var(--font-size);
        height: var(--font-size);
    }
    :hover {
        fill: var(--text-muted);
    }
}

.searchSubmitIcon {
    all: unset;
    position: absolute;
    right: 5px;
    width: var(--font-size);
    height: var(--font-size);
    padding: 10px;
    background-color: var(--cademy-blue);
    fill: white;
    border-radius: 100px;
    cursor: pointer;
}

.listbox {
    display: none;
    box-shadow: var(--card-box-shadow-hover);
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-pill);
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
    top: calc(100% + var(--padding-small));
    &.open {
        display: block;
    }
}

.icon {
    width: var(--font-size);
    height: var(--font-size);
}
