.alert {
    border: 1px solid var(--text);
    border-radius: var(--border-radius);
    padding: var(--padding-small);
}

.info {
    background-color: var(--info-bg);
    color: var(--info-border);
    border-color: var(--info-border);
}

.warning {
    background-color: var(--warning-bg);
    border-color: var(--warning-border);
}

.danger {
    background-color: var(--danger-bg);
    color: var(--danger-border);
    border-color: var(--danger-border);
}

.success {
    background-color: var(--success-bg);
    color: var(--success-border);
    border-color: var(--success-border);
}
