.fieldContainer {
    margin: var(--padding) 0;
    display: grid;
    position: relative;
    box-sizing: border-box;

    input {
        box-sizing: border-box;
        color: var(--text-alt);
        border: 1px solid var(--border);
        border-radius: var(--border-radius);
        font-family: var(--font-family);
        font-size: var(--font-size);
        font-weight: var(--font-weight-normal);
        line-height: var(--font-size);
        width: 100%;
        max-width: 100%;
        padding: var(--padding);

        &:focus {
            box-shadow:
                inset 0 0 2px rgb(67 90 111 / 14%),
                inset 0 0 0 1px #579ad9,
                0 0 0 3px rgb(16 112 202 / 14%);
            outline: none;
        }
    }

    /**
     * For the dynamic label to work it requires a placeholder to be set on all fields
     * so we can target inputs with a value. The placeholder will never be shown.
     */
    input::placeholder {
        color: transparent;
    }

    label {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: var(--padding);
        padding-block-start: 1.4em;
        line-height: var(--font-size);
        font-size: var(--font-size);
        pointer-events: none;
        transition: all 0.15s ease 0s;
    }

    input:focus,
    input:not(:placeholder-shown) {
        padding-top: 1.6em;
        padding-bottom: 0.9em;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
        color: rgba(0, 0, 0, 0.8);
        font-size: var(--font-size-extra-small);
        left: 0.5em;
        top: -0.4em;
    }

    label span {
        color: var(--red);
    }
}

.useRequired .fieldContainer {
    input:required ~ label::after {
        content: '*';
        font-size: var(--font-size);
        color: var(--red);
        margin-inline-start: 0.2em;
    }
}

.useOptional .fieldContainer {
    input:not(:required) ~ label::after {
        content: 'Optional';
        box-sizing: border-box;
        font-size: var(--font-size-tiny);
        background-color: rgba(0, 0, 0, 0.05);
        padding: 0.125em 0.3125em 0.125em 0.3125em;
        border-radius: var(--border-radius);
        position: absolute;
        margin-inline-start: var(--font-size);
    }
}

.description {
    font-size: var(--font-size-extra-small);
    color: var(--grey);
    margin-block-start: var(--margin-big);
    margin-inline-start: var(--margin-small);
}

.errorMessage {
    padding-inline-start: var(--padding-small);
    padding-block-start: var(--padding-small);
    color: var(--red);
    font-size: var(--font-size-extra-small);
}

.tagline {
    text-align: center;
}
