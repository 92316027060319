.container {
    display: flex;
    width: 100%;
    div {
        flex-grow: 2;
    }
    position: relative;
    max-width: 767px;
    margin: 0 auto;
}
