.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    width: 70px;
    padding-block: 3em;

    .spinner {
        stroke: var(--brand-color);
    }
}
