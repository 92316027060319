.trigger {
    display: flex;
    gap: var(--padding-tiny);
    &:hover {
        border: 1px solid var(--border);
        cursor: pointer;
    }
    transition-property: color, background-color;
    transition-duration: 0.5s;
}

@media screen and (max-width: 767px) {
    // Show icons only on mobile
    .trigger {
        > * {
            display: none;
        }
        > svg {
            display: block;
        }
    }
}
