.button {
    font-size: var(--font-size-smaller);
    color: var(--text-heading);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button:hover {
    text-decoration: underline;
}
