.actionBar {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
}

.passwordSection {
    margin-block-start: 2em;
    padding-block-start: 1em;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-block-end: 2em;
    padding-block-end: 1em;
}
