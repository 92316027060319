.accountLogo {
    border-radius: 5px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 35px;
    height: 35px;
    position: relative;
    border: 1px solid var(--border);
}

.educatorImage {
    object-fit: contain;
}

.defaultImage {
}
