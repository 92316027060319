.head {
    border-bottom: 1px solid var(--bg-grey-dark);
    padding-block: var(--padding-smaller);

    h1 {
        font-family: var(--font-family);
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bold);
        color: #222;
        text-align: center;
    }
}

.body {
    padding: var(--padding);
}

.olly {
    height: 110px;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
