.typicalWrapper::after {
    content: '|';
    animation: blink 1s infinite step-start;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

@media (prefers-reduced-motion) {
    .typicalWrapper::after {
        content: none;
    }
}
