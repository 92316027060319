.header {
    padding: var(--padding);
    background-color: var(--white);
    border-bottom: 1px solid var(--border);

    .header_container {
        max-width: 1200px;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas: 'logo search nav-buttons';
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 auto;
        gap: var(--gap-list);
    }

    .cademy_header_logo {
        grid-area: logo;
        margin-inline-start: calc(var(--margin) * -1);
    }

    .searchBar {
        grid-area: search;
    }

    input.menuCheckbox {
        display: none;
    }

    .hamburgerButton {
        display: none;
    }

    nav {
        grid-area: nav-buttons;
        display: flex;
        align-items: center;
        gap: var(--padding-small);
    }
}

div.mobile_nav {
    position: fixed;
    background: var(--bg-2);
    max-height: 0;
    transition: max-height 0.2s ease-out;
    height: 100vh;
    overflow: auto;
    width: 100vw;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    > div {
        padding: var(--padding);
    }
    label {
        font-weight: var(--font-weight-bolder);
    }
    ul {
        padding: 0;
    }
    li {
        list-style: none;
        padding: var(--padding-tiny) var(--padding-small);
        margin: var(--padding-tiny) 0px;
    }
    .social_icons {
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
            display: flex;
            flex-direction: row;
        }

        svg {
            height: 22px;
            fill: var(--link-color);
        }
    }
    .made_with_love {
        display: block;
        max-width: 1200px;
        text-align: center;
        padding-block: var(--padding);
        margin-inline: auto;
        margin-block-end: var(--padding);
        a {
            color: var(--text);
        }
    }
}

@media screen and (min-width: 767px) {
    div.mobile_nav {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .header {
        padding: var(--padding-small) var(--padding);
        .hamburgerButton {
            display: block;
        }
        .callToAction {
            display: none;
        }

        .header_container {
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;
            grid-template-areas:
                'logo nav-buttons'
                'search search';
        }

        nav {
            justify-content: flex-end;
        }
    }
}

.hamburgerButton {
    cursor: pointer;
    float: right;
    padding: var(--padding) var(--padding-small);
    z-index: 1001;
}

.hamburgerLine {
    background: var(--text);
    display: block;
    height: 0.125em;
    position: relative;
    width: 1.5em;
    &::before,
    &::after {
        background: var(--text);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
    }
    &::before {
        top: 0.313em;
    }
    &::after {
        top: -0.313em;
    }
}

.menuCheckbox:checked {
    & ~ div.mobile_nav {
        max-height: 100vh;
    }
    & ~ .hamburgerButton {
        position: fixed;
        right: 20px;
    }
    & ~ .hamburgerButton .hamburgerLine {
        background: transparent;
        &::before {
            transform: rotate(-45deg);
            top: 0;
        }
        &::after {
            transform: rotate(45deg);
            top: 0;
        }
    }
}
