.button {
    --_foregroundColor: var(--foregroundColor, var(--text));
    --_backgroundColor: var(--backgroundColor, var(--white));
    --_borderColor: var(--borderColor, var(--border));

    display: flex;
    gap: var(--margin-small);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    letter-spacing: -0.05px;
    font-family: unset;
    border-radius: var(--border-radius);
    padding: 0 var(--padding);
    height: 40px;
    line-height: 40px;
    font-weight: var(--font-weight-bolder);
    font-size: var(--font-size-smaller);
    border: 1px solid var(--_borderColor);
    background-color: var(--_backgroundColor);
    color: var(--_foregroundColor);
    fill: var(--_foregroundColor);
    box-sizing: border-box;
    cursor: pointer;
    &:focus {
        border: 1px solid var(--_borderColor);
        outline: -webkit-focus-ring-color auto 1px;
    }
    &:hover {
        border: 1px solid var(--_borderColor);
        opacity: 0.95;
        transition: all 0.2s ease 0s;
    }
    &:disabled {
        background-color: var(--bg-2);
        color: var(--text-muted);
        fill: var(--text-muted);
        border: 1px solid transparent;
        &:hover {
            cursor: not-allowed;
        }
        &:hover {
            background-color: var(--bg-2);
            color: var(--text-muted);
            fill: var(--text-muted);
            border: 1px solid transparent;
            box-shadow: none;
        }
    }
}

.danger {
    --borderColor: transparent;
    --backgroundColor: var(--red);
    --foregroundColor: var(--white);
    &:hover {
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px 2px inset;
    }
}

.primary {
    --borderColor: transparent;
    --backgroundColor: var(--brand-color);
    --foregroundColor: var(--on-brand-color);
    &:hover {
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px 2px inset;
    }
}

.secondary {
    --borderColor: transparent;
    --backgroundColor: var(--text);
    --foregroundColor: var(--white);
}

.success {
    --borderColor: transparent;
    --backgroundColor: var(--green);
    --foregroundColor: var(--white);
    &:hover {
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px 2px inset;
    }
}

.warning {
    --borderColor: transparent;
    --backgroundColor: var(--warning-border);
    --foregroundColor: var(--text);
    &:hover {
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px 2px inset;
    }
}

.textButton,
.textDangerButton {
    --borderColor: transparent;
    --backgroundColor: inherit;
    --foregroundColor: inherit;
    &:focus {
        --borderColor: var(--border);
    }
    &:hover {
        --backgroundColor: var(--bg-2);
        box-shadow: none;
    }
}

.textDangerButton {
    --foregroundColor: var(--red);
}

.icon {
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 1em;
        height: 1em;
    }
}

@media only screen and (max-width: 400px) {
    .button.iconOnlyMobile {
        width: 2.8em;
        overflow: hidden;
        color: transparent;
        position: relative;
        svg {
            position: absolute;
            left: 0.75em;
            top: 0.75em;
        }
    }
}
